<template>
  <ion-header>
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
              <ion-title>Neues HCPI setzen (alle Einträge werden gelöscht)!</ion-title>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-icon style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" class="ion-float-right"></ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-item>
        <ion-label position="stacked">Ziel HCPI</ion-label>
        <ion-input type="text" v-model="hcpi"></ion-input>
      </ion-item>
      <ion-button @click="resetHcpi()" expand="block" color="success">{{ $t('save') }}</ion-button>
    </ion-list>
  </ion-content>
</template>

<script>
import { toastController, IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonDatetime, IonItem, IonList, IonIcon, IonGrid, IonCol, IonRow, modalController, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, closeCircleOutline } from 'ionicons/icons';

import { useMutation } from '@vue/apollo-composable'
import resetHcpiMutationFile from '../graphql/resetHcpiMutation.mutation.gql'

export default defineComponent({
  name: 'ResetHcpiDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonDatetime, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonSelect, IonSelectOption },
  props: ['propsData'],
  data() {
    return {
      scoringRecord: this.propsData.scoringRecord,
      hcpi: 10,
    }
  },
  mounted(){
    //console.log(this.propsData.scoringRecord)
  },
  setup() {
    const { mutate: resetHcpiMutation } = useMutation(resetHcpiMutationFile, { fetchPolicy: 'no-cache' });

    return {
      closeOutline,
      closeCircleOutline,
      resetHcpiMutation,
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss();
    },
    async resetHcpi() {
      this.resetHcpiMutation({         
        dgvPlayerId: this.scoringRecord.playerId,
        newHcpi: parseFloat(this.hcpi),
        })
        .then(res => {
          toastController
            .create({
              message: "HCPI setzen erfolgreich aktualisert",
              duration: 2000,
              color: 'success'
            }).then(res => res.present())
            return modalController.dismiss(true)
        })
        .catch(err => {
          toastController
            .create({
              message: "Beim setzen des HCPI ist ein Fehler aufgetreten",
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
        })
    }

  }
});
</script>