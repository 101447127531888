<template>
  <ion-header>
    <ion-toolbar>
      <ion-grid>
        <ion-row>
          <ion-col>
            <div>
              <ion-title>Low HCPI setzen!</ion-title>
            </div>
          </ion-col>
          <ion-col>
            <div>
              <ion-icon style="font-size: 30px;" @click="closeDialog()" :ios="closeOutline" :md="closeOutline" class="ion-float-right"></ion-icon>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding">
    <ion-list lines="full" class="ion-no-margin">
      <ion-item>
        <ion-label position="stacked">Low HCPI</ion-label>
        <ion-input type="text" v-model="lowHcpi"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Freeze bis</ion-label>
        <ion-datetime v-model="lowHcpiFreezeUntil" :max="maxData"></ion-datetime>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">Freeze Low HCPI</ion-label>
        <ion-select :value="String(freezeLowHcpi)" @ionChange="freezeLowHcpi = $event.target.value">
          <ion-select-option value="true">Ja</ion-select-option>
          <ion-select-option value="false">Nein</ion-select-option>
        </ion-select>
      </ion-item>
      <ion-button @click="setLowHcpi()" expand="block" color="success">{{ $t('save') }}</ion-button>
    </ion-list>
  </ion-content>
</template>

<script>
import { toastController, IonButton, IonContent, IonHeader, IonTitle, IonToolbar, IonLabel, IonInput, IonDatetime, IonItem, IonList, IonIcon, IonGrid, IonCol, IonRow, modalController, IonSelect, IonSelectOption } from '@ionic/vue';
import { defineComponent } from 'vue';
import { closeOutline, closeCircleOutline } from 'ionicons/icons';

import { useMutation } from '@vue/apollo-composable'
import setLowHcpiMutationFile from '../graphql/setLowHcpiMutation.mutation.gql'

export default defineComponent({
  name: 'setLowHcpiDialog',
  components: { IonButton, IonContent, IonHeader, IonTitle, IonToolbar,IonLabel, IonInput, IonDatetime, IonItem, 
                IonList, IonIcon, IonGrid, IonCol, IonRow, IonSelect, IonSelectOption },
  props: ['propsData'],
  data() {
    return {
      scoringRecord: this.propsData.scoringRecord,
      lowHcpiFreezeUntil: this.propsData.scoringRecord.lowHcpiFreezeUntil == null ? new Date().toISOString() : this.propsData.scoringRecord.lowHcpiFreezeUntil,
      lowHcpi: this.propsData.scoringRecord.lowHcpi,
      freezeLowHcpi: true,
      maxData: (new Date()).getFullYear() + 5
    }
  },
  mounted(){
    //console.log(this.propsData)
    //console.log('here', this.lowHcpiFreezeUntil)
  },
  setup() {
    const { mutate: setLowHcpiMutation } = useMutation(setLowHcpiMutationFile, { fetchPolicy: 'no-cache' });

    return {
      closeOutline,
      closeCircleOutline,
      setLowHcpiMutation,
    }
  },
  methods: {
    closeDialog() {
      return modalController.dismiss();
    },
    setLowHcpi() {
      this.setLowHcpiMutation({
        dgvPlayerId: this.scoringRecord.playerId,
        lowHcpi: parseFloat(this.lowHcpi),
        lowHcpiFreezeUntil: this.lowHcpiFreezeUntil,
        freezeLowHcpi: this.freezeLowHcpi == 'false' ? false : true,
        })
        .then(res => {
          toastController
            .create({
              message: "Low HCPI setzen erfolgreich aktualisert",
              duration: 2000,
              color: 'success'
            }).then(res => res.present())
          this.closeDialog()
        })
        .catch(err => {
          toastController
            .create({
              message: "Beim speichern des Low HCPI ist ein Fehler aufgetreten",
              duration: 2000,
              color: 'danger',
            }).then(res => res.present())
        })
    }

  }
});
</script>